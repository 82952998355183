import React from "react";
import { Router } from "@reach/router";
import NodeStatus from "../components/PageComponents/NodeStatusPage";
import NodeDetails from "../components/PageComponents/NodeDetails";

const nodeStatus = (props) => (
  <Router>
    <NodeStatus {...props} path="/nodeStatus" />
    <NodeDetails {...props} path="/nodeStatus/node-details" />
  </Router>
);

export default nodeStatus;
