import React, { useEffect, useState } from "react";
import { Field, Form as FinalForm } from "react-final-form";
import { get } from "lodash";
import { useToast } from "@chakra-ui/react";
import Route from "../../base/Route";
import Section from "../../base/Section";
import Box from "../../base/Box";
import Dropdown from "../../base/Dropdown";
import { ALL_FLEET } from "../../../utilities/constants";
import { get as GET } from "../../../utilities/http";
import toastMessage from "../../../utilities/toastMessage";
import Button from "../../base/Button";
import NodeStatusTable from "../NodeStatusTable";

const NodeStatus = () => {
  const toast = useToast();
  const [cityDropDownOptions, setCityDropDownOptions] = useState([]);
  const [systemDropDownOptions, setSystemDropDownOptions] = useState([]);
  const [subsystemDropDownOptions, setSubsystemDropDownOptions] = useState([]);
  const [lineDropDownOptions, setLineDropDownOptions] = useState([]);
  const [unitDropDownOptions, setUnitDropDownOptions] = useState([]);
  // const [unitData, setUnitData] = useState({});
  useEffect(async () => {
    try {
      const { data } = await GET("/listing-cities");
      const cityDropDownData = data.map((city) => ({
        label: `#${get(city, "city_id")} - ${get(city, "city")}`,
        value: get(city, "city_id"),
      }));
      setCityDropDownOptions([
        { label: "All", value: ALL_FLEET },
        ...cityDropDownData,
      ]);
    } catch (e) {
      toastMessage({ message: e.message, toast, type: "error" });
    }
  }, []);
  const gap = 4;
  const colStyled = {
    flex: 1,
    fontSize: 1,
    maxWidth: ["100%", null, null, "50%"],
    mb: gap,
    pr: gap,
  };
  const nodeArray = [
    {
      data_availability: "node1 data",
      icc: "node1 icc",
      isdn: "node1 isdn",
      last_ping: "node1 ping",
      mac_eth: "node1 mac",
      name: "node 1",
      node_id: 1,
      status: 1,
      unit: "unit1",
      unit_id: 1,
    },
    {
      data_availability: "node2 data",
      icc: "node2 icc",
      isdn: "node2 isdn",
      last_ping: "node2 ping",
      mac_eth: "node2 mac",
      name: "node 2",
      node_id: 2,
      status: 1,
      unit: "unit1",
      unit_id: 1,
    },
    {
      data_availability: "node3 data",
      icc: "node3 icc",
      isdn: "node3 isdn",
      last_ping: "node3 ping",
      mac_eth: "node3 mac",
      name: "node 3",
      node_id: 3,
      status: 1,
      unit: "unit1",
      unit_id: 1,
    },
    {
      data_availability: "node4 data",
      icc: "node4 icc",
      isdn: "node4 isdn",
      last_ping: "node4 ping",
      mac_eth: "node4 mac",
      name: "node 4",
      node_id: 4,
      status: 1,
      unit: "unit2",
      unit_id: 2,
    },
    {
      data_availability: "node5 data",
      icc: "node5 icc",
      isdn: "node5 isdn",
      last_ping: "node5 ping",
      mac_eth: "node5 mac",
      name: "node 5",
      node_id: 5,
      status: 1,
      unit: "unit3",
      unit_id: 3,
    },
    {
      data_availability: "node6 data",
      icc: "node6 icc",
      isdn: "node6 isdn",
      last_ping: "node6 ping",
      mac_eth: "node6 mac",
      name: "node 6",
      node_id: 6,
      status: 1,
      unit: "unit2",
      unit_id:2,
    },
    {
      data_availability: "node7 data",
      icc: "node7 icc",
      isdn: "node7 isdn",
      last_ping: "node7 ping",
      mac_eth: "node7 mac",
      name: "node 7",
      node_id: 7,
      status: 1,
      unit: "unit1",
      unit_id: 1,
    },
    {
      data_availability: "node8 data",
      icc: "node8 icc",
      isdn: "node8 isdn",
      last_ping: "node8 ping",
      mac_eth: "node8 mac",
      name: "node 8",
      node_id: 8,
      status: 1,
      unit: "unit1",
      unit_id: 1,
    },
    {
      data_availability: "node9 data",
      icc: "node9 icc",
      isdn: "node9 isdn",
      last_ping: "node9 ping",
      mac_eth: "node9 mac",
      name: "node 9",
      node_id: 9,
      status: 0,
      unit: "unit1",
      unit_id: 1,
    },
    {
      data_availability: "node10 data",
      icc: "node10 icc",
      isdn: "node10 isdn",
      last_ping: "node10 ping",
      mac_eth: "node10 mac",
      name: "node 10",
      node_id: 10,
      status: 0,
      unit: "unit3",
      unit_id: 3,
    },
    {
      data_availability: "node11 data",
      icc: "node11 icc",
      isdn: "node11 isdn",
      last_ping: "node11 ping",
      mac_eth: "node11 mac",
      name: "node 11",
      node_id: 11,
      status: 0,
      unit: "unit1",
      unit_id: 1,
    },
    {
      data_availability: "node12 data",
      icc: "node12 icc",
      isdn: "node12 isdn",
      last_ping: "node12 ping",
      mac_eth: "node12 mac",
      name: "node 12",
      node_id: 12,
      status: 0,
      unit: "unit2",
      unit_id: 2,
    },
    {
      data_availability: "node13 data",
      icc: "node13 icc",
      isdn: "node13 isdn",
      last_ping: "node13 ping",
      mac_eth: "node13 mac",
      name: "node 13",
      node_id: 13,
      status: 0,
      unit: "unit3",
      unit_id: 3,
    },
    {
      data_availability: "node14 data",
      icc: "node14 icc",
      isdn: "node14 isdn",
      last_ping: "node14 ping",
      mac_eth: "node14 mac",
      name: "node 14",
      node_id: 14,
      status: 0,
      unit: "unit2",
      unit_id: 2,
    },
    {
      data_availability: "node15 data",
      icc: "node15 icc",
      isdn: "node15 isdn",
      last_ping: "node15 ping",
      mac_eth: "node15 mac",
      name: "node 15",
      node_id: 15,
      status: 0,
      unit: "unit2",
      unit_id: 2,
    },
  ];
  // const tableData = getTableData(unitArray.units);
  return (
    <Route isPrivate layout="admin">
      <Section>
        <Box>
          <FinalForm
            onSubmit={async (values) => {
              const formValues = {
                city_id: values.city_id,
                line_id: values.line_id,
                subsystem_id: values.subsystem_id,
                system_id: values.system_id,
                unit_id: values.unit_id,
              };
              console.log("---valuess---", formValues);
              /* try {
                              const searchParams = stringify({
                                filters: stringify(formValues),
                                page: stringify({ offset: 0, pageSize: 10 }),
                              });
                              const data = await GET(
                                `/listing-unit?${searchParams ? "?" : ""}${searchParams}`
                              );
                              if (data) {
                                const filteredUnitData = get(data, "data", []);
                                setUnitData(filteredUnitData);
                                // setLoading(false);
                              }
                            } catch (e) {
                              // setLoading(false);
                              toastMessage({ message: e.message, toast, type: "error" });
                            } */
            }}
            render={(formContext) => (
              <form onSubmit={formContext.handleSubmit}>
                <Box sx={{ display: "flex", flexDirection: "column", mb: 8 }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: ["column", null, "row"],
                      mb: 2,
                    }}
                  >
                    <Box
                      as={Field}
                      component={Dropdown}
                      name="city_id"
                      onChange={async (value) => {
                        try {
                          const data = await GET(
                            `/listing-system?city_id=${value}`
                          );
                          const systemDropDownData = get(data, "data", []).map(
                            (system) => ({
                              label: `#${get(system, "system_id")} - ${get(
                                system,
                                "system"
                              )}`,
                              value: get(system, "system_id"),
                            })
                          );
                          setSystemDropDownOptions([
                            { label: "All", value: ALL_FLEET },
                            ...systemDropDownData,
                          ]);
                        } catch (e) {
                          toastMessage({
                            message: e.message,
                            toast,
                            type: "error",
                          });
                        }
                      }}
                      options={cityDropDownOptions}
                      placeholder="Select City"
                      sx={{ maxWidth: "100%" }}
                      wrapperSx={{ ...colStyled }}
                    />
                    <Box
                      as={Field}
                      component={Dropdown}
                      name="system_id"
                      onChange={async (value) => {
                        try {
                          const data = await GET(
                            `/listing-subsystem?system_id=${value}`
                          );
                          const subsystemDropDownData = get(
                            data,
                            "data",
                            []
                          ).map((subsystem) => ({
                            label: `#${get(subsystem, "subsystem_id")} - ${get(
                              subsystem,
                              "subsystem"
                            )}`,
                            value: get(subsystem, "subsystem_id"),
                          }));
                          setSubsystemDropDownOptions([
                            { label: "All", value: ALL_FLEET },
                            ...subsystemDropDownData,
                          ]);
                        } catch (e) {
                          toastMessage({
                            message: e.message,
                            toast,
                            type: "error",
                          });
                        }
                      }}
                      options={systemDropDownOptions}
                      placeholder="Select System"
                      sx={{ maxWidth: "100%" }}
                      wrapperSx={{ ...colStyled }}
                    />
                    <Box
                      as={Field}
                      component={Dropdown}
                      name="subsystem_id"
                      onChange={async (value) => {
                        try {
                          const data = await GET(
                            `/listing-line?subsystem_id=${value}`
                          );
                          const lineDropDownData = get(data, "data", []).map(
                            (line) => ({
                              label: `#${get(line, "line_id")} - ${get(
                                line,
                                "line"
                              )}`,
                              value: get(line, "line_id"),
                            })
                          );
                          setLineDropDownOptions([
                            { label: "All", value: ALL_FLEET },
                            ...lineDropDownData,
                          ]);
                        } catch (e) {
                          toastMessage({
                            message: e.message,
                            toast,
                            type: "error",
                          });
                        }
                      }}
                      options={subsystemDropDownOptions}
                      placeholder="Select Subsystem"
                      sx={{ maxWidth: "100%" }}
                      wrapperSx={{ ...colStyled }}
                    />
                    <Box
                      as={Field}
                      component={Dropdown}
                      name="line_id"
                      onChange={async (value) => {
                        try {
                          const data = await GET(
                            `/listing-unit?line_id=${value}`
                          );
                          const unitDropDownData = get(data, "data", []).map(
                            (unit) => ({
                              label: `#${get(unit, "unit_id")} - ${get(
                                unit,
                                "unit"
                              )}`,
                              value: get(unit, "unit_id"),
                            })
                          );
                          setUnitDropDownOptions([
                            { label: "All", value: ALL_FLEET },
                            ...unitDropDownData,
                          ]);
                        } catch (e) {
                          toastMessage({
                            message: e.message,
                            toast,
                            type: "error",
                          });
                        }
                      }}
                      options={lineDropDownOptions}
                      placeholder="Select Line"
                      sx={{ maxWidth: "100%" }}
                      wrapperSx={{ ...colStyled }}
                    />
                    <Box
                      as={Field}
                      component={Dropdown}
                      name="unit_id"
                      options={unitDropDownOptions}
                      placeholder="Select Unit"
                      sx={{ maxWidth: "100%" }}
                      wrapperSx={{ ...colStyled }}
                    />
                  </Box>
                  <Box
                    sx={{
                      alignItems: "flex-end",
                      display: "flex",
                      flexDirection: ["column", null, "row"],
                      justifyContent: "flex-end",
                    }}
                  >
                    {/* <Location>
                      {({ location: { pathname } }) => (
                          <Button
                              onClick={() => {
                                formContext.form.reset({
                                  city_id: "",
                                  line_id: "",
                                  subsystem_id: "",
                                  system_id: "",
                                });
                                navigate(pathname);
                              }}
                              sx={{ maxWidth: "100px" }}
                              variant="primary"
                          >
                            Reset
                          </Button>
                      )}
                    </Location> */}
                    <Button
                      disabled={formContext.submitting}
                      submitting={formContext.submitting}
                      sx={{ maxWidth: "100px", ml: 3 }}
                      variant="primary"
                    >
                      Mostrar
                    </Button>
                  </Box>
                </Box>
              </form>
            )}
          />
        </Box>
        <Box sx={{ my: 5 }}>
          <NodeStatusTable data={nodeArray} title="Nodes" />
        </Box>
      </Section>
    </Route>
  );
};

export default NodeStatus;
