import PropTypes from "prop-types";
import React from "react";
import Box from "../Box";

const Card = ({ children, sx, hoverSx, ...rest }) => {
  const styleSx = {
    ":hover": {
      boxShadow: 3,
      ...hoverSx,
    },
    bg: "white",
    borderRadius: 4,
    boxShadow: 1,
    p: 8,
    ...sx,
  };
  return (
    <Box sx={{ ...styleSx }} {...rest}>
      {children}
    </Box>
  );
};

Card.propTypes = {
  children: PropTypes.node.isRequired,
  hoverSx: PropTypes.shape({}),
  sx: PropTypes.shape({}),
};

Card.defaultProps = {
  hoverSx: {},
  sx: {},
};

export default Card;
