import React, { useEffect, useState } from "react";
import { get } from "lodash";
import { useTheme, useToast } from "@chakra-ui/react";
import { get as GET } from "../../../utilities/http";
import toastMessage from "../../../utilities/toastMessage";
import Route from "../../base/Route";
import Section from "../../base/Section";
import Card from "../../base/Card";
import Heading from "../../base/Heading";
import AddUpdateSubsystemForm from "../../forms/AddUpdateSubSystemForm";
import Box from "../../base/Box";

const NodeDetails = ({ location }) => {
  console.log("-----hbajdbhjbhjabkjdsadna");
  const theme = useTheme();
  const toast = useToast();
  const nodeId = get(location, "state.node", null);
  const [nodeDetails, setNodeDetails] = useState({});
  useEffect(async () => {
    try {
      const unitAssociationData = await GET(`/listing-assoc?node_id=${nodeId}`);
      setNodeDetails(
        unitAssociationData
          ? { ...nodeDetails, ...unitAssociationData.data[0] }
          : { ...nodeDetails }
      );
      const simAssociationData = await GET(
        `/listing-sims-assoc?node_id=${nodeId}`
      );
      setNodeDetails(
        simAssociationData
          ? { ...nodeDetails, ...simAssociationData.data[0] }
          : { ...nodeDetails }
      );
    } catch (e) {
      toastMessage({ message: e.message, toast, type: "error" });
    }
  }, []);
  return (
    <Route isPrivate layout="admin">
      <Section containerSx={{ display: "flex", justifyContent: "center" }}>
        <Card
          sx={{
            bg: "white",
            maxWidth: "800px",
            width: "800px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>}</Box>
        </Card>
      </Section>
    </Route>
  );
};

export default NodeDetails;
